<template>
  <div>
    <v-progress-linear
      v-if="mainPageLoading"
      color="primary"
      indeterminate
    />
    <page-header class="mb-4">
      {{ $t('tableManage') }}
      <template #action>
        <v-btn
          elevation="0"
          color="primary lighten-4 black--text"
          order="-1"
          style="background-color: #ffff87;"
          @click="startAddSection(null)"
        >
          <v-icon
            left
            color="black"
          >
            mdi-plus
          </v-icon>
          {{ $t('addNewPartition') }}
        </v-btn>
      </template>
    </page-header>
    <div
      class="d-flex filterBar align-center flex-wrap"
    >
      <v-badge
        v-for="section in sectionList"
        :key="section.id"
        :content=" section.tableCount "
        color="error"
        offset-x="24px"
        overlap
      >
        <v-btn
          :color="sectionIsActive(section)?'primary':''"
          :outlined="!sectionIsActive(section)"
          class="mr-2 mb-2"
          elevation="0"
          style="background: white"
          @click="activeSectionId=section.id"
        >
          {{ section.name }}
        </v-btn>
      </v-badge>
      <v-spacer/>
      <v-btn
        v-if="selectedTableList.length>0"
        class="mr-2 mb-2"
        elevation="0"
        order="-1"
        outlined
        style="background: white"
        :icon="!$vuetify.breakpoint.mdAndUp"
        @click="showChangeSectionDialog=true"
      >
        <v-icon
          :center="!$vuetify.breakpoint.mdAndUp"
          :left="$vuetify.breakpoint.mdAndUp"
        >
          mdi-swap-horizontal
        </v-icon>
        <span
          v-if="$vuetify.breakpoint.mdAndUp"
          class="text-truncate"
          style="max-width: 170px;"
        >
          {{ $t('SwitchPartition') }}
        </span>
      </v-btn>
    </div>
    <div
      v-if="waitLoading"
      style="display: grid;grid-template-columns: repeat(auto-fill,minmax(150px,1fr)); grid-gap: 8px"
    >
      <v-card
        class="pa-4"
        elevation="0"
        outlined
        @click="startAddSection(activeSectionId)"
      >
        <v-responsive
          :aspect-ratio="1"
        >
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <v-icon large>
              mdi-pencil
            </v-icon>
            <div class="text-caption text-center">
              {{ $t('editPartition') }}
            </div>
          </div>
        </v-responsive>
      </v-card>
      <v-card
        class="pa-4"
        elevation="0"
        outlined
        @click="deleteSection"
      >
        <v-responsive
          :aspect-ratio="1"
        >
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <div class="text-body-1">
              <v-icon large>
                mdi-delete
              </v-icon>
            </div>
            <div class="text-caption text-center">
              {{ $t('deletePartition') }}
            </div>
          </div>
        </v-responsive>
      </v-card>
      <v-card
        class="pa-4"
        elevation="0"
        outlined
        @click="startAddTable"
      >
        <v-responsive
          :aspect-ratio="1"
        >
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <div class="text-body-1">
              <v-icon large>
                mdi-plus
              </v-icon>
            </div>
            <div class="text-caption text-center">
              {{ $t('addNewTable') }}
            </div>
          </div>
        </v-responsive>
      </v-card>
      <v-card
        v-for="(t,index) in displayTables"
        :key="t.id"
        :color="t.usageStatus==='1'?'#ddd':'#eee'"
        :disabled="t.usageStatus==='1'"
        class="pa-4"
        elevation="0"
        @click="startEditTable(index)"
      >
        <v-btn
          icon
          style="position: absolute;left: 0px;top:0px;border-radius: 12px;z-index: 2"
          @click.stop="selectTable(t.id)"
        >
          <v-icon
            color="grey"
          >
            <template v-if="selectedTableList.includes(t.id)">
              mdi-checkbox-outline
            </template>
            <template v-else>
              mdi-checkbox-blank-outline
            </template>
          </v-icon>
        </v-btn>


        <v-menu
          close-on-content-click
          left
          offset-overflow
          offset-y
          rounded
        >
          <template #activator="{on,attrs}">
            <v-btn
              icon
              style="position: absolute;right: 0px;top:0px;border-radius: 12px;z-index: 2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list
            outlined
            width="200px"
          >
            <v-list-item-group>
              <v-list-item @click.stop="startEditTable(index)">
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="deleteTable(t)">
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-responsive
          :aspect-ratio="1"
          style="position: relative"
        >
          <div
            class="d-flex align-center flex-column mt-6"
            style="height: 100%"
          >
            <div class="text-h4 font-weight-medium">
              {{ t.name }}
            </div>
            <div class="mt-2 d-flex align-center font-weight-regular text-caption">
              <p>{{ $t('tableForPeople', [t.seatCount ? t.seatCount : 0]) }}</p>
            </div>
          </div>
        </v-responsive>
      </v-card>
    </div>
    <v-dialog
      v-model="showChangeSectionDialog"
      max-width="360"
    >
      <v-card
        color="grey lighten-4"
        class="pa-4"
      >
        <div class="text-h4">
          {{ $t('SelectPartition') }}
        </div>
        <div
          style="display: grid;grid-template-columns: repeat(auto-fill,minmax(96px,1fr));grid-gap: 12px"
          class="mt-4"
        >
          <v-card
            v-for="s in sectionList"
            :key="s.id"
            elevation="0"
            @click="moveTableTo(s.id)"
          >
            <v-responsive :aspect-ratio="1">
              <div
                style="height: 100%"
                class="d-flex align-center justify-center flex-column"
              >
                {{ s.name }}
              </div>
            </v-responsive>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <ik-entity-form
      v-model="showAddSectionDialog"
      :edited-index="editSectionId"
      :model="sectionModel"
      @need-refresh="reloadData"
    />
    <v-dialog
      v-model="showMassAddTableDialog"
      max-width="600px"
    >
      <v-card class="pa-4">
        <div class="text-h4 font-weight-bold">
          {{ $t('batchAddTable') }}
        </div>
        <v-card
          class="pa-2 mt-4 text-body-2"
          outlined
        >
          {{ $t('addTableRule') }}
        </v-card>
        <div class="text-overline mt-6">
          {{ $t('tableFirstNumber') }}
        </div>
        <v-text-field
          v-model="startTableNumber"
          autofocus
          class="text-uppercase"
          outlined
          type="number"
        />
        <div class="text-overline">
          {{ $t('tableEndNumber') }}
        </div>
        <v-text-field
          v-model="endTableNumber"
          :placeholder="startTableNumber"
          class="text-uppercase"
          outlined
          type="number"
        />
        <div class="text-overline">
          {{ $t('tableNumberOfPeople') }}
        </div>
        <v-text-field
          v-model="seatCount"
          class="text-uppercase"
          outlined
          type="number"
        />
        <v-btn
          :disabled="!startTableNumber"
          :loading="loading"
          block
          color="primary"
          elevation="0"
          @click="addTable"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card>
    </v-dialog>
    <ik-entity-form
      v-model="showEditTableDialog"
      :edited-index="editTableId"
      :model="model"
      :out-side-list="displayTables"
      @need-refresh="reloadData"
    />
  </div>
</template>

<script>

  import Section from '../../../model/Section'
  import { addMultipleTable, printAllQR } from '@/common/Utlis/api'
  import Utils from '../../../common/Utlis/Utils'
  import Table from '@/model/tableAndPerson/Table'
  import IKUtils from 'innerken-js-utils'
  import { IkEntityForm } from 'metaflow-js'
  import { showSuccessMessage } from '@/common/utils'
  import i18n from '../../../i18n'
  import PageHeader from '@/composable/InK/typography/PageHeader'

  export default {
    components: {
      IkEntityForm,
      PageHeader,
    },
    data: function () {
      return {

        mainPageLoading: false,
        waitLoading: false,
        sectionList: [],
        tableList: [],

        model: Table,
        sectionModel: Section,

        showMassAddTableDialog: null,
        startTableNumber: null,
        endTableNumber: null,

        showEditTableDialog: null,
        editTableId: null,
        tableName: '',
        seatCount: 4,

        activeSectionIndex: null,
        activeSectionId: null,
        showAddSectionDialog: null,
        sectionName: '',
        editSectionId: null,
        loading: false,

        showChangeSectionDialog: null,
        selectedTableList: [],

      }
    },
    computed: {
      displayTables () {
        return this.tableList.filter(it => it.sectionId === this.activeSectionId)
      },
      occupiedTableName () {
        return this.tableList.map(it => it.name)
      },
    },
    mounted () {
      this.reloadData()
    },
    methods: {
      async actionAnd (action) {
        this.loading = true
        try {
          await action()
          await this.reloadData()
          showSuccessMessage()
        } catch (e) {

        }

        this.loading = false
      },

      selectTable (id) {
        if (this.selectedTableList.includes(id)) {
          this.selectedTableList = this.selectedTableList.filter(it => it !== id)
        } else {
          this.selectedTableList.push(id)
        }
      },

      startAddSection (id = -1) {
        const index = this.sectionList.findIndex(it => it.id === id)
        this.showAddSectionDialog = true
        this.editSectionId = index
      },

      async deleteSection () {
        const res = await IKUtils.showConfirmAsyn(i18n.t('DeletePartitionHint'), i18n.t('attention') + '⚠')

        if (res.isConfirmed) {
          await this.actionAnd(async () => {
            const removeTable = this.tableList
              .filter(it => it.sectionId === this.activeSectionId && parseInt(it.usageStatus) === 0).map(async it => {
                return await Table.remove(it.id)
              })
            await Promise.allSettled(removeTable)
            await Section.remove(this.activeSectionId)
          })
        }
      },

      async deleteTable (t) {
        const res = await IKUtils.showConfirmAsyn(i18n.t('DeleteTableHint'), i18n.t('OnceDeletedCannotRecovered') + ' ⚠')
        if (res.isConfirmed) {
          await this.actionAnd(async () => {
            await Table.remove(t.id)
            this.showEditTableDialog = false
          })
        }
      },
      async moveTableTo (sectionId) {
        await this.actionAnd(async () => {
          const tables = this.tableList.filter(it => this.selectedTableList.includes(it.id))
          for (const table of tables) {
            table.sectionId = sectionId
            await Table.edit(table)
          }
          await this.reloadData()
        })
        this.showChangeSectionDialog = false
      },

      startEditTable (table) {
        this.editTableId = table
        this.showEditTableDialog = true
      },

      startAddTable () {
        this.startTableNumber = null
        this.endTableNumber = null
        this.seatCount = 4
        this.showMassAddTableDialog = true
      },

      async addTable () {
        await this.actionAnd(async () => {
          await addMultipleTable(this.activeSectionId, this.startTableNumber, this.endTableNumber ?? this.startTableNumber, 0, this.seatCount)
        })
        this.startTableNumber = null
        this.endTableNumber = null
        this.showMassAddTableDialog = false
      },

      sectionIsActive (section) {
        return section.id === this.activeSectionId
      },

      async printQR () {
        await printAllQR()
        Utils.toast(i18n.t('print_success'))
      },

      async reloadData () {
        this.mainPageLoading = true
        this.waitLoading = false
        const oldId = this.activeSectionId
        this.sectionList = (await Section.getList(true)).filter(it => it.id !== 6)
        this.tableList = (await Table.getList(true))
        if (!this.sectionList.find(it => it.id === oldId)) {
          this.activeSectionId = this.sectionList[0].id
        }
        this.selectedTableList = []
        this.waitLoading = true
        this.mainPageLoading = false
      },
    },
  }
</script>
